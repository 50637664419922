.bg-light {
  background-color: #f5f5f5 !important;
}

// Here you can add other styles
.table__network {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      background-color: #f2f2f2;
      padding: 10px;
      text-align: left;
      font-weight: bold;
    }
  }

  tbody {
    td {
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f5f5f5;
    }
  }
}

.bg-damas-grey {
  background-color: #beaa63 !important;
}

.bg-damas-blue {
  background-color: #6b4d9f !important;
}
.bg-damas-primary {
  background-color: #e7eddb !important;
}
.bg-damas-secondary {
  background-color: #a1b090 !important;
}

.sidebar {
  background-color: #5451e8 !important;
}
.sidebar-brand {
  background-color: #5451e8 !important;
}
.sidebar-toggler {
  background-color: #5451e8 !important;
}
.sidebar-nav {
  padding-top: 25px !important;
  padding-right: 16px;
}

.sidebar-nav .nav-link {
  color: #fff !important;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom: 12px;
}

.sidebar-nav .nav-group.show {
  // background: #000!important;
  border-radius: 8px !important;
}

.sidebar-nav {
  .nav-link {
    &.active {
      color: #2c384a !important;
      background: #f1f2f8 !important;
      svg {
        path {
          color: #2c384a !important;
        }
      }
    }
  }
}

.sidebar-nav .nav-link .nav-icon {
  color: #fff !important;
}

.btn-primary {
  background-color: #5451e8;
  border: #fff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #e7eddb;
  color: black;
}

.error_text {
  color: #d8000c;
}

.activity-feed {
  padding: 15px;
  list-style: none;

  .feed-item {
    position: relative;
    padding-bottom: 20px;
    padding-left: 30px;
    border-left: 2px solid #e4e8eb;

    &:last-child {
      border-color: transparent;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -6px;
      width: 10px;
      height: 10px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #f37167;
    }

    .date {
      display: block;
      position: relative;
      top: -5px;
      color: #8c96a3;
      text-transform: uppercase;
      font-size: 13px;
    }
    .text {
      position: relative;
      top: -3px;
    }
  }
}

.notification-bar {
  .box {
    position: absolute;
    width: 350px;
    right: 0px;
    background: #f5f5f5;
    padding: 20px;
    height: 200px;
    overflow-y: scroll;
  }
  a {
    text-decoration: none;
    color: black;
  }

  // a:visited{
  //   color: black;
  // }

  .box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .notification {
    padding-top: 15px;
    position: relative;
    display: inline-block;
  }

  .number {
    height: 25px;
    width: 24px;
    background-color: #d63031;
    border-radius: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 10px;
    border-style: solid;
    border-width: 2px;
  }

  .number:empty {
    display: none;
  }

  .notBtn {
    transition: 0.5s;
    cursor: pointer;
  }

  .fas {
    font-size: 25pt;
    padding-bottom: 10px;
    color: black;
    margin-right: 40px;
    margin-left: 40px;
  }

  .box {
    width: 400px;
    height: 0px;
    border-radius: 10px;
    transition: 0.5s;
    position: absolute;
    overflow-y: scroll;
    padding: 0px;
    left: -350px;
    margin-top: 5px;
    background-color: #f4f4f4;
    -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    cursor: context-menu;
  }

  .fas:hover {
    color: #d63031;
  }

  .notBtn:hover > .box {
    height: 60vh;
  }

  .content {
    padding: 20px;
    color: black;
    vertical-align: middle;
    text-align: left;
  }

  .gry {
    background-color: #f4f4f4;
  }

  .top {
    color: black;
    padding: 10px;
  }

  .display {
    position: relative;
  }

  .cont {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
  }

  .cont:empty {
    display: none;
  }

  .stick {
    text-align: center;
    display: block;
    font-size: 50pt;
    padding-top: 70px;
    padding-left: 80px;
  }

  .stick:hover {
    color: black;
  }

  .cent {
    text-align: center;
    display: block;
  }

  .sec {
    padding: 10px 10px;
    background-color: #f4f4f4;
    transition: 0.5s;
  }

  .profCont {
    padding-left: 15px;
  }

  .profile {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 50px;
    float: left;
  }

  .txt {
    vertical-align: top;
    font-size: 1rem;
    padding: 0px 0px 0px 80px;
  }

  .sub {
    font-size: 1rem;
    color: grey;
  }

  .new {
    border-style: none none solid none;
    border-color: red;
  }

  .sec:hover {
    background-color: #bfbfbf;
  }

  .box::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .box::-webkit-scrollbar-thumb {
    background-color: black;
    border: 2px solid black;
    border-radius: 5px;
  }

  header {
    -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    height: 110px;
    vertical-align: middle;
  }

  h1 {
    float: left;
    padding: 10px 30px;
  }
}

.custom-background-image {
  background-repeat: no-repeat;
  background-size: 100% 58%;
  .card-header {
    justify-content: left !important;
    border-bottom: none !important;
  }
}

.order-first .card-body .col .fw-semibold {
  height: 28px;
}

.dashboard-card .card-body .text-medium-emphasis {
  margin-bottom: 0px !important;
}

/* For 1024 Resolution */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .custom-background-image {
    background-repeat: no-repeat;
    background-size: 100% 52%;
  }
}

/* For 1366 Resolution */
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .custom-background-image {
    background-repeat: no-repeat;
    background-size: 100% 48%;
  }
}

/**Folder Design **/

$folder-color: #ffc107;
$folder-color-light: ligthen($folder-color, 7.5%);
$folder-color-dark: darken($folder-color, 7.5%);

.card-folders {
  .card-body > .breadcrumb {
    margin-left: -1.25em;
    margin-right: -1.25em;
    margin-top: -1.25em;
    border-radius: 0;
  }
}

.folder {
  &-container {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    width: 100px;
    // type button
    padding: 0;
    align-self: start;
    background: none;
    border: none;
    outline-color: transparent !important;
    cursor: pointer;
  }
  &-icon {
    font-size: 3em;
    line-height: 1.25em;
  }
  &-icon-color {
    color: $folder-color;
    text-shadow: 1px 1px 0px $folder-color-dark;
  }
  &-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.flex-column {
  .folder {
    &-container {
      display: flex;
      width: auto;
      min-width: 100px;
      text-align: left;
      margin: 0;
      margin-bottom: 1rem;
    }
    &-icon,
    &-name {
      display: inline-flex;
    }
    &-icon {
      font-size: 1.4em;
      margin-right: 1rem;
    }
  }
}

.file {
  &-icon-color {
    color: #999;
    //text-shadow: 1px 1px 0px grey;
  }
}

.apply-filters-btn {
  display: inline-grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaignCard-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0px 1rem;
}

.campaignCard {
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 27px;
  flex-basis: 23%;
}

.campaign-card svg {
  fill: #5451e8;
}
.campaignCard:hover {
  // box-shadow: 0px 0px 1px 2px #5451e8;
  background-color: #5451e8;
  color: white;
  cursor: pointer;
  .data-count {
    color: #cfceff;
  }
}

.campaignCard:hover svg {
  fill: white;
}
.campaignCard h3 {
  margin-top: 0px;
}

.data-count {
  // padding: 4px 8px;
  color: #606060;
  // border-radius: 4px;
  font-size: 16px;
}

.campaignCardRow {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#dataTable {
  max-width: 100% !important;
}

#dataTable th,
td {
  white-space: nowrap;
}

.columns-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.columns-header-div {
  // border: 1px solid #ccc;
  padding: 1px 3px;
}

.columns-header-div-active {
  border: 1px solid #5451e8;
  // padding: 1px 3px;
  text-shadow: 0px 0px 1px #5451e8;
  padding: 1px 3px;

  background-color: #f2f2fc;
  border-radius: 4px;
}

.campaign-card-title {
  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20rem;
    font-size: 1rem;
    font-weight: bold;
  }

  div {
    span {
      margin-left: 0.5rem;
      // background: #5451e8;
      // padding: 0.2rem 0.44rem;
      color: #5451e8;
      // border-radius: 0.4rem;
    }
  }
}

.text-gray {
  color: #969494;
}

.font-bold {
  font-weight: bold;
}

.campaign-card-back a {
  color: #7e7d7d;
  margin-right: 1rem;
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}

.header-card-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 0px 0rem;

  .header-card {
    display: flex;
    padding: 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-basis: 24%;
    // gap: 10px;
    // flex: 1 0 0;
    // border-radius: 24px;
    // background: #7e28d4;
    // background: #fff;
    // border: 1px solid #d3cbfb;
    // color: white;
    position: relative;
    overflow: hidden;
    .shape {
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 50%;
      background: rgba(91, 107, 255, 0.1);
      &.shape1 {
        right: -100px;
        top: -30px;
      }
      &.shape2 {
        right: -60px;
        bottom: -30px;
        // animation: shapemove 5s infinite;
      }
    }
    .header-card-img-box {
      img {
        width: 84px;
      }
    }
    .header-card-text-box {
      p {
        padding-bottom: 0.5rem;
      }
      .header-card-text-count {
        font-size: 48px;
        font-weight: 700;
      }
      .header-card-text-total {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    // .header-card
  }
  // .data {
  //   background: #914456;
  //   // background: #d45128;
  // }
  // .users {
  //   background: #FF9A3E;
  //   // background: #2882d4;
  // }
  // .files {
  //   background-color: #332A7C;
  //   // background-color: #d428c3;
  // }
}

// @keyframes shapemove {
//   from {
//     width: 150px;
//     height: 150px;
//     border-radius: 50%;
//     right: -40px;
//     bottom: -30px;
//   }
//   to {
//     width: 100%;
//     height: 100%;
//     border-radius: 0;
//     right: 0;
//     bottom: 0;
//   }
// }

.dashboard-body {
  display: flex;
  padding: 0px 1rem;
  justify-content: space-between;
  .card {
    flex-basis: 49%;
  }

  .recently-open-wrapper {
    .recently-open-title {
      font-size: 24px;
      font-weight: 590;
    }
    .recently-open-card-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      .recently-open-card {
        display: flex;
        gap: 10px;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;

          .title {
            font-size: 16px;
            font-weight: 510;
          }
          .name {
            font-size: 14px;
            color: #939393;
          }
        }
      }
    }
  }
}

.data-table-wrapper {
  width: 100%;
  overflow-x: auto;
  max-height: 70vh;
}

.search-icon-btn {
  border-radius: 0px 5px 5px 0px;
}

.projects {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.projects-wrapper {
  padding: 12px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.apply-filters-filter-headers {
  background-color: #fff;
  li {
    cursor: pointer;
  }
  li a {
    color: #646464 !important;
  }
  .active {
    color: #5451e8 !important;
    border-bottom-color: #5451e8 !important;
  }
}

.apply-filters-btn-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px 0px;
  // background-color: #e9e9ff;
  button::disabled {
    background-color: #5451e8;
    color: white;
  }
}

.apply-filters-total-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  color: #23a34e;
  background-color: #cbffca;
  border: 1px solid #d3cbfb;
  font-weight: 600;
}

.common-modal-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  background: #e8e8ff;
  color: #000;
  border-radius: 4px;
  .switch {
    margin: 0px !important;
  }
  label {
    font-weight: 400;
  }
  margin-bottom: 0.5rem;
  margin-right: 12px;
}

.common-modal-search-box {
  margin-bottom: 0.5rem;
}

.common-modal-selected-header-count {
  margin-bottom: 0.5rem;
  background: #e9e9ff;
  padding: 4px 8px;
  color: #5451e8;
  border-radius: 4px;
}

.btn-custom {
  background-color: #ffffff;
  color: #5451e8;
  border-radius: 4px;
  border: 1px solid #5451e8;
  padding: 6px 10px;
}

.btn-custom:hover {
  background-color: #5451e8;
  color: #ffffff;
}

.user-management-table-permission {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.create-user-permissions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.main-data-table {
  th {
    padding: 12px 16px;
    background: #f8f8f8;
    color: #323232;
  }
  td {
    padding: 8px 16px;
  }
}

.apply-filters-filter-headers-wrapper {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.delete-icon {
  svg {
    color: white;
    fill: white;
  }
}

.margin-right-2 {
  margin-right: 0.5rem;
}

.margin-right-4 {
  margin-right: 1rem;
}

.margin-left-2 {
  margin-left: 0.5rem;
}

.margin-left-4 {
  margin-left: 1rem;
}

//Custom CSS //

.dc-quite-text-style {
  font-size: 12px;
  // text-transform: uppercase;
  // letter-spacing: 1px;
}
.project-list-card {
  .btn {
    padding: 8px 12px;
  }
  .project-details {
    .project-details-list {
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -20px;
        background: #f1f2f8;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .file {
      width: 4rem;
    }
    .date {
      width: 5rem;
    }
    .created-by {
      width: 10rem;
      P {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .shared-to {
      width: 5rem;
    }
  }
}

.nofiles-share {
  margin-bottom: 24px;
  background: #f1f2f8;
  padding: 24px;
  border-radius: 50%;
  img {
    max-width: 250px;
  }
}
.nofiles-share-title {
  font-size: 24px;
  font-weight: 300;
}

.dc-searchbox {
  background-color: #f1f2f8;
  border: 1px solid #d3cbfb;
  border-radius: 12px;
  overflow: hidden;
  .form-control {
    border: 1px solid #f1f2f8;
    border-radius: 12px;
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .btn {
    border: 0;
    background-color: #f1f2f8;
    height: 100%;
  }
}

.projectfile-img {
  img {
    width: 32px;
  }
}

.form-control {
  padding: 8px 16px;
  background-color: #f1f2f8;
  border: 1px solid #f1f2f8;
  &:focus {
    background-color: #fff;
    border: 1px solid #d3cbfb;
    box-shadow: none;
  }
}
.form-select {
  padding: 8px 16px !important;
  background-color: #f1f2f8;
  border: 1px solid #f1f2f8;
  &:focus {
    background-color: #fff;
    border: 1px solid #d3cbfb;
    box-shadow: none;
  }
}

.report-filter {
  .btn {
    padding: 8px 32px;
  }
}

.filter-tab {
  .btn {
    padding: 8px 16px;
    &.active {
      color: #fff;
    }
    &:hover {
      color: #fff;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border: 10px solid #f1f2f8;
      border-radius: 16px;
      .btn {
        padding: 8px 16px;
      }
      .input-group {
        .btn {
          height: 100%;
          background: #f1f2f8;
          border: 1px solid #f1f2f8;
        }
      }
      .common-modal-search-box {
        .btn {
          height: 100%;
          background: #f1f2f8;
          border: 1px solid #f1f2f8;
        }
      }
      .css-qbdosj-Input {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.table {
  .btn {
    padding: 6px !important;
  }
}

.user-managment-card {
  .btn {
    padding: 12px 32px;
  }
}

.color-danger {
  color: #dc3545;
}

.dc-chart {
  .chart-wrapper {
    width: 72%;
    margin: 0 auto;
  }
}

.bg-gray-50 {
  background-color: #f9f9f9;
}
.bg-gray-100 {
  background-color: #e9ecef;
}
.bg-gray-200 {
  background-color: #dee2e6;
}
.bg-gray-300 {
  background-color: #ced4da;
}
.bg-gray-400 {
  background-color: #adb5bd;
}
.bg-gray-500 {
  background-color: #495057;
}
.bg-gray-600 {
  background-color: #343a40;
}
.bg-gray-700 {
  background-color: #212529;
}
.bg-transparent {
  background-color: #212529;
}

.btn-light {
  background-color: #eaeaff;
  color: #5451e8;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: 0;
  svg {
    path {
      fill: #5451e8;
    }
  }
  &:hover {
    background-color: #d7d7f5;
    border: 0;
    color: #5451e8;
  }
}
.btn-ghost-light {
  background-color: transparent;
  color: #ababab;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: 0;
  svg {
    path {
      fill: #ababab;
    }
  }
  &:hover {
    background-color: transparent;
    border: 0;
    color: #ababab;
  }
}

.v-align {
  vertical-align: middle;
}
.d-card-one {
  border: 2px solid #478cff;
  background-color: #eff5ff;
  .header-card-text-total {
    color: #478cff;
  }
  .header-card-text-count {
    color: #123a7c;
  }
}
.d-card-two {
  border: 2px solid #ffc77e;
  background-color: #fff8ef;
  .header-card-text-total {
    color: #ffb547;
  }
  .header-card-text-count {
    color: #854e08;
  }
}
.d-card-three {
  border: 2px solid #ff8694;
  background-color: #ffeff1;
  .header-card-text-total {
    color: #ff8694;
  }
  .header-card-text-count {
    color: #de001a;
  }
}
.d-card-four {
  border: 2px solid #74cb74;
  background-color: #edffed;
  .header-card-text-total {
    color: #74cb74;
  }
  .header-card-text-count {
    color: #008000;
  }
}

.form-switch-xl .form-check-input {
  width: 3em;
}
.form-switch-xl .form-check-label {
  margin-left: 8px !important;
}

.dashboard-filter {
  .form-select {
    background-color: #fff !important;
    border-color: #bfbeff;
  }
  .form-control {
    background-color: #fff !important;
    border-color: #bfbeff;
  }
}

.project-report-card {
  .card-col {
    .bg-card {
      .progress {
        background-color: #fff;
      }
      .c-title {
        font-size: 32px;
        color: #323232 !important;
      }
      .c-subtitle {
        font-size: 16px;
        color: #323232 !important;
        opacity: 75%;
      }
    }
    &:first-child {
      .bg-card {
        background-color: #edd1e0;
      }
    }
    &:nth-child(2) {
      .bg-card {
        background-color: #f0dede;
      }
    }
    &:nth-child(3) {
      .bg-card {
        background-color: #f1eff0;
      }
    }
    &:nth-child(4) {
      .bg-card {
        background-color: #f8ecd7;
      }
    }
    &:nth-child(5) {
      .bg-card {
        background-color: #f8d5c1;
      }
    }
    &:nth-child(6) {
      .bg-card {
        background-color: #f1eb86;
      }
    }
    &:nth-child(7) {
      .bg-card {
        background-color: #eff1d9;
      }
    }
    &:nth-child(8) {
      .bg-card {
        background-color: #f8d5db;
      }
    }
    &:nth-child(9) {
      .bg-card {
        background-color: #ddecef;
      }
    }
    &:nth-child(10) {
      .bg-card {
        background-color: #96dfce;
      }
    }
    &:last-child {
      .bg-card {
        background-color: #e8dad7;
      }
    }
  }
}

.filter-btn {
  background-color: #f4f4ff;
  border: 1px solid #d6d6ff;
  &:hover {
    border: 1px solid #d6d6ff;
  }
}

.table-btn {
  width: 32px !important;
  height: 32px !important;
  border-radius: 8px !important;
  border: 1px solid #d6d6ff;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.c-checkbox {
  .form-check {
    cursor: pointer;
    .form-check-label {
      width: 100% !important;
      span {
        width: 100% !important;
        display: flex;
      }
    }
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #f8f8f8 !important;
}
